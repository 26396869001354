import React from 'react'
import { graphql } from 'gatsby'
import { COLOR } from '@farewill/ui/tokens'

import FAREWILL_JSONLD from 'lib/json-ld/farewill'
import JSONLD from 'components/JSONLD'
import DefaultLayout from 'layouts/DefaultLayout'
import CategoryBanner from 'views/general/public/ContentHub/components/CategoryBanner'
import Section from 'views/general/public/ContentHub/components/Section'
import { Category } from './types'

const SectionSwitcher = ({
  index,
  category,
}: {
  index: number
  category: Category
}): React.ReactElement => {
  switch (index) {
    case 1:
    case 4:
      return (
        <Section
          content={category}
          limit={3}
          background={COLOR.ACCENT.PRIMARY}
          textColor={COLOR.BLACK}
          hasWhiteButton
          hasTextures
        />
      )
    case 2:
      return (
        <Section
          content={category}
          limit={3}
          background={COLOR.ACCENT.PRIMARY_10}
          hasBottomImages
        />
      )
    case 0:
    case 3:
    default:
      return <Section content={category} background={COLOR.WHITE} />
  }
}

const ArticleCategory = ({
  data,
}: {
  data: GatsbyTypes.ArticleCategoryGroupBySlugQuery
}): React.ReactElement => {
  const { categoryGroup } = data

  return (
    <>
      <JSONLD data={FAREWILL_JSONLD} />
      <DefaultLayout title={categoryGroup?.title}>
        <>
          <CategoryBanner title={categoryGroup?.title} />
          {categoryGroup?.categories?.map((category, index: number) => (
            <SectionSwitcher
              index={index}
              category={category as Category}
              key={category?.contentful_id}
            />
          ))}
        </>
      </DefaultLayout>
    </>
  )
}

export const query = graphql`
  query ArticleCategoryGroupBySlug($slug: String!) {
    categoryGroup: contentfulContentHubCategoryGroup(slug: { eq: $slug }) {
      title
      categories {
        title
        contentful_id
        slug
        highlightedArticles {
          ...HighlightedArticle
        }
      }
    }
  }
`

export default ArticleCategory
