import React from 'react'
import styled from 'styled-components'
import { Image } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

const StyledTexture = styled(Image)`
  position: absolute;
  pointer-events: none;
`

const StyledDotsTopLeft = styled(StyledTexture)`
  top: -48px;
  left: -80px;

  ${screenMax.s`
    max-width: 200px;
    top: -16px;
    left: -48px;
  `}
`

const StyledSwoopBottomRight = styled(StyledTexture)`
  bottom: -140px;
  right: 0;
  transform: rotate(-90deg);

  ${screenMax.s`
    max-width: 240px;
    bottom: -80px;
    right: -160px;
  `}
`

const StyledDotsBottomRight = styled(StyledTexture)`
  bottom: 0;
  right: 0;

  ${screenMax.s`
    max-width: 600px;
    right: -100px;
  `}
`

const TextureStyleB = (): React.ReactElement => (
  <>
    <StyledDotsTopLeft
      path="textures/detail_new_yellow02"
      width={240}
      loading="auto"
    />
    <StyledSwoopBottomRight
      path="textures/detail-1"
      width={320}
      loading="auto"
    />
    <StyledDotsBottomRight
      path="textures/dots-pattern-yellow-02"
      width={800}
      loading="auto"
    />
  </>
)

export default TextureStyleB
