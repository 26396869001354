import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ChevronRightIcon } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'

const StyledChevron = styled(ChevronRightIcon)`
  margin: 0 ${GTR.XS};
  width: ${GTR.XS};
`

const ChevronSeparator = ({ children, className, tag: Element }) => (
  <Element className={className}>
    {children.reduce((memo, child, i) => {
      if (i === 0) {
        return memo.concat(child)
      }

      return [
        ...memo,
        // eslint-disable-next-line react/no-array-index-key
        <StyledChevron key={i} inline color={COLOR.GREY.DARK} />,
        child,
      ]
    }, [])}
  </Element>
)

ChevronSeparator.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
}

ChevronSeparator.defaultProps = {
  className: '',
  tag: 'div',
}

export default ChevronSeparator
