import React from 'react'
import styled from 'styled-components'
import { Image } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

const StyledTexture = styled(Image)`
  position: absolute;
  pointer-events: none;
`

const StyledTextureBottomLeft = styled(StyledTexture)`
  top: 40%;
  left: -10%;
  transform: rotate(-90deg);

  ${screenMax.m`
    max-width: 550px;
    bottom: -250px;
    left: -200px;
    top: auto;
  `}
`

const StyledTextureTopLeft = styled(StyledTexture)`
  top: 0;
  bottom: 0;
  left: 0;

  ${screenMax.m`
    max-width: 200px;
    top: auto;
  `}
`

const StyledTextureTopRight = styled(StyledTexture)`
  top: 0;
  right: -10%;

  ${screenMax.m`
    max-width: 400px;
  `}
`

const StyledTextureBottomRight = styled(StyledTexture)`
  top: 0;
  right: 0;

  ${screenMax.m`
    max-width: 500px;
    right: -200px;
  `}
`

const TextureStyleA = (): React.ReactElement => (
  <>
    <StyledTextureBottomLeft
      path="textures/fingerprint_yellow02"
      width={700}
      loading="auto"
    />
    <StyledTextureTopLeft
      path="textures/detail-1-light"
      width={300}
      loading="auto"
    />
    <StyledTextureTopRight
      path="textures/detail-4-dark"
      width={700}
      loading="auto"
    />
    <StyledTextureBottomRight
      path="textures/detail_new_yellow02-light"
      width={400}
      loading="auto"
    />
  </>
)

export default TextureStyleA
