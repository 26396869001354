export const ARTICLES_PER_PAGE = 12

export const MIN_BANNER_HEIGHT = {
  LARGE_SCREENS: '320px',
  SMALL_SCREENS: '60vh',
}

export const CONTENT_HUB_SEARCH_OPTIONS = {
  COMPANY_CATEGORIES: [
    '1VNHHIQWVG4dQ9OPHHktbU', // Talking about death
    '5XCrLkbMpJJ7BnJrx8j9a8', // Leaving a legacy
    '1gMX0CWsReDQIS6CTanmZJ', // Working with death
  ],
}
