import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { ArrowRightIcon, Button, Grid, H, Image, P } from '@farewill/ui'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'
import { formatPathProps } from 'types/types'
import { ARTICLE_TYPES } from 'lib/contentful/constants'
import { IMAGE_POSITION, Article } from '../types'

const StyledDivider = styled.div`
  width: 72px;
  height: 10px;
  border-radius: 2px;
  background-color: ${COLOR.ACCENT.PRIMARY_60};
  margin-bottom: ${GTR.M};
`

const StyledGrid = styled(Grid)`
  align-items: center;
  text-decoration: none;
  height: 100%;
  margin-bottom: 0;

  ${({ $isLarge }) => !$isLarge && `align-items: stretch;`}

  &:hover {
    text-decoration: none;

    h2 {
      text-decoration: underline;
    }

    svg {
      transform: translateX(8px);
    }
  }

  svg {
    transition: transform 0.2s ease-in-out;
  }
`

const StyledImage = styled(Image)`
  max-height: 250px;
  object-fit: contain;
  border-radius: ${BORDER.RADIUS.S};
`

const StyledImageWrapper = styled(Grid.Item)<{
  $imagePosition: IMAGE_POSITION
}>`
  ${({ $imagePosition }) =>
    $imagePosition === IMAGE_POSITION.RIGHT &&
    `
    text-align: right;
  `};

  ${({ $imagePosition }) =>
    $imagePosition === IMAGE_POSITION.BOTTOM &&
    `
    display: flex;
    align-items: flex-end;
  `};

  ${screenMax.m`
    text-align: center;
  `}
`

const StyledButton = styled(Button.Plain)<{
  $color: string
}>`
  ${({ $color }) => `color: ${$color}`}
`

const SingleArticle = ({
  data,
  imagePosition,
  textColor,
}: Article): React.ReactElement => {
  const isLarge =
    imagePosition === IMAGE_POSITION.LEFT ||
    imagePosition === IMAGE_POSITION.RIGHT
  return (
    <StyledGrid
      tag={Link}
      to={`${data.type === ARTICLE_TYPES.BLOG ? '/blog' : '/articles'}/${
        data.slug
      }`}
      margin={['XL', 0]}
      gap="S"
      $isLarge={isLarge}
    >
      <Grid.Item
        spanFromL={isLarge ? 6 : 12}
        startColumnFromL={imagePosition === IMAGE_POSITION.LEFT ? 7 : 1}
      >
        <StyledDivider />
        <H tag="h2" size={isLarge ? 'M' : 'S'}>
          {data.title}
        </H>
        <P color={textColor || COLOR.GREY.DARK}>
          {data.description?.description}
        </P>
        <StyledButton
          tag="span"
          icon={ArrowRightIcon}
          flush
          $color={textColor || COLOR.GREY.DARK}
        >
          Read more
        </StyledButton>
      </Grid.Item>
      {(isLarge || imagePosition === IMAGE_POSITION.BOTTOM) && (
        <StyledImageWrapper
          spanFromL={isLarge ? 6 : 12}
          $imagePosition={imagePosition}
          centered={!isLarge}
        >
          {data.image ? (
            <StyledImage
              path={data.image.file?.url}
              width={400}
              stretch
              ext={['webp', 'png']}
              formatPath={({ path, width, ext }: formatPathProps) =>
                `${path}?w=${width}&fm=${ext}`
              }
            />
          ) : (
            <StyledImage
              path="illustrations/cropped/blob-with-laptop-and-cat"
              width={600}
              formatPath={({ path, ext, width }: formatPathProps) =>
                `${CLOUDINARY_ROOT_PATH}/c_pad,f_auto,q_auto,w_${width},h_${Math.floor(
                  width * 0.45
                )}/${path}.${ext}`
              }
              stretch
            />
          )}
        </StyledImageWrapper>
      )}
    </StyledGrid>
  )
}
export default SingleArticle
