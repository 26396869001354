/* eslint-disable prefer-destructuring */
import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Button, Grid, H, Wrapper, Image } from '@farewill/ui'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'
import { FormatPathArgs } from '@farewill/ui/components/Image/types'
import { screenMax } from '@farewill/ui/helpers/responsive'
import PATHS from 'lib/navigation/paths'
import SingleArticle from './SingleArticle'
import { IMAGE_POSITION } from '../types'

const MINIMUM_HIGHLIGHTED_ARTICLES = 4

const StyledTextureWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
`

const StyledTexture = styled(Image)`
  position: absolute;
  pointer-events: none;
`

const StyledTextureLeft = styled(StyledTexture)`
  top: 0;
  left: 0;
  transform: scale(-1, -1);

  ${screenMax.m`
    max-width: 550px;
    top: 0;
    left: 0;
  `}
`

const StyledTextureRight = styled(StyledTexture)`
  bottom: 0;
  right: 0;
  opacity: 0.5;

  ${screenMax.m`
    max-width: 550px;
    bottom: 0;
    right: 0;
  `}
`

const StyledContent = styled(Wrapper)`
  z-index: 1;
  position: relative;
`

const StyledHeader = styled(H)`
  font-size: 40px;
`

const Textures = (): React.ReactElement => (
  <>
    <StyledTextureLeft
      path="textures/detail-4-dark"
      width={250}
      loading="auto"
      formatPath={({ path, ext, width }: FormatPathArgs) =>
        `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,a_90,w_${width}/${path}.${ext}`
      }
    />
    <StyledTextureRight path="textures/pattern-2" width={800} loading="auto" />
  </>
)
interface SectionProps {
  content: GatsbyTypes.Maybe<GatsbyTypes.SectionFragment>
  background?: string
  hasBottomImages?: boolean
  hasWhiteButton?: boolean
  hasTextures?: boolean
  limit?: number
  textColor?: string
}

const Section = ({
  content,
  background,
  hasBottomImages,
  hasWhiteButton,
  hasTextures,
  limit,
  textColor,
}: SectionProps): React.ReactElement => {
  const highlightedArticles = limit
    ? content?.highlightedArticles?.slice(0, limit)
    : content?.highlightedArticles
  const contentSize = highlightedArticles?.length || 0
  const url = `${PATHS.GENERAL.CONTENT_HUB}/${content?.slug}`
  const MoreButton = hasWhiteButton ? Button.White : Button.Primary

  const getImagePosition = (index: number): IMAGE_POSITION | undefined => {
    if (contentSize > MINIMUM_HIGHLIGHTED_ARTICLES && index === 1) {
      return IMAGE_POSITION.LEFT
    }

    if (contentSize > MINIMUM_HIGHLIGHTED_ARTICLES - 1 && index === 0) {
      return IMAGE_POSITION.RIGHT
    }

    return undefined
  }

  return (
    <StyledTextureWrapper
      padding={['M', 0, 'L']}
      paddingFromM={['XL', 0]}
      background={background}
      id={content?.slug}
    >
      {hasTextures && <Textures />}
      <StyledContent container>
        <Grid gapFromXL="L">
          <Grid.Item
            startColumnFromM={2}
            spanFromM={10}
            startColumnFromL="unset"
            spanFromL={12}
          >
            <StyledHeader tag="h2" decorative margin={[0, 0, 'XL']}>
              {content?.title}
            </StyledHeader>
          </Grid.Item>

          {highlightedArticles?.map(
            (article, index) =>
              article && (
                <Grid.Item
                  key={article.contentful_id}
                  startColumnFromM={2}
                  spanFromM={10}
                  startColumnFromL="unset"
                  spanFromL={getImagePosition(index) ? 12 : 4}
                >
                  <SingleArticle
                    data={article as GatsbyTypes.ContentfulArticle}
                    imagePosition={
                      hasBottomImages
                        ? IMAGE_POSITION.BOTTOM
                        : getImagePosition(index)
                    }
                    textColor={textColor}
                  />
                </Grid.Item>
              )
          )}
        </Grid>
        <Wrapper centered margin={['XXL', 0]}>
          <MoreButton tag={Link} to={url}>
            See more guides
          </MoreButton>
        </Wrapper>
      </StyledContent>
    </StyledTextureWrapper>
  )
}

export default Section
