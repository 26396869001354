export enum IMAGE_POSITION {
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
}

export enum MEDIA_TYPES {
  VIDEO = 'Video',
  TEAM_MEMBER = 'TeamMember',
}

export type Article = {
  data: GatsbyTypes.ContentfulArticle
  imagePosition?: IMAGE_POSITION
  textColor?: string
}

export type MediaItem = {
  id: string
  title: string
  description: string
  image: string
  slug: string
}

export type ToolsItem = {
  id: string
  title: string
  description: string
  image: string
  path: string
}
