import React from 'react'
import { BackgroundImageWrapper, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import styled from 'styled-components'
import TextureStyleA from './TextureStyleA'
import TextureStyleB from './TextureStyleB'
import TextureStyleC from './TextureStyleC'

const StyledTextureWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
`

const StyledContentWrapper = styled(Wrapper)`
  position: relative;
`

interface YellowBannerProps {
  children: React.ReactNode
  className?: string
  textureStyle?: 'A' | 'B' | 'C'
  centered?: boolean
}

const YellowBanner = ({
  className,
  children,
  textureStyle,
  centered = true,
}: YellowBannerProps): React.ReactElement => {
  let textures

  if (textureStyle === 'A') {
    textures = <TextureStyleA />
  } else if (textureStyle === 'B') {
    textures = <TextureStyleB />
  } else if (textureStyle === 'C') {
    textures = <TextureStyleC />
  }
  return (
    <>
      {textureStyle ? (
        <StyledTextureWrapper
          className={className}
          background={COLOR.ACCENT.PRIMARY}
          centered={centered}
        >
          {textures}
          <StyledContentWrapper container>{children}</StyledContentWrapper>
        </StyledTextureWrapper>
      ) : (
        <BackgroundImageWrapper
          className={className}
          imagePath="textures/squiggle-1"
          imageWidth={1000}
          imageWidthFromM={1500}
          imageWidthFromL={2000}
          backgroundPosition="center 30%"
          centered={centered}
          style={{ backgroundColor: COLOR.ACCENT.PRIMARY }}
        >
          <Wrapper container>{children}</Wrapper>
        </BackgroundImageWrapper>
      )}
    </>
  )
}

export default YellowBanner
