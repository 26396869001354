import React from 'react'
import styled from 'styled-components'
import { Image } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

const StyledTexture = styled(Image)`
  position: absolute;
  pointer-events: none;

  ${screenMax.s`
    display: none;
  `}
`

const StyledDotsTopLeft = styled(StyledTexture)`
  top: 0px;
  left: -10px;
`

const StyledSwoopBottomRight = styled(StyledTexture)`
  bottom: 0px;
  right: 0;
  transform: scale(-1, 1);

  ${screenMax.s`
    max-width: 240px;
    bottom: 0px;
    right: 0px;
  `}
`

const StyledDotsBottomRight = styled(StyledTexture)`
  bottom: 0;
  right: 0;

  ${screenMax.s`
    max-width: 600px;
    right: -100px;
  `}
`

const TextureStyleB = (): React.ReactElement => (
  <>
    <StyledDotsTopLeft
      path="textures/yellow-blob-3"
      width={180}
      loading="auto"
    />
    <StyledSwoopBottomRight path="textures/bg-2" width={320} loading="auto" />
    <StyledDotsBottomRight
      path="textures/dots-pattern-yellow-02"
      width={520}
      loading="auto"
    />
  </>
)

export default TextureStyleB
